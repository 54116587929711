import React from "react"
import styled from "@emotion/styled"

const Row = ({ children, ...rest }) => {
  const style = {
    alignContent: `flex-start`,
    alignItems: `flex-start`,
    display: `flex`,
    flexDirection: `row`,
    flexWrap: `wrap`,
    justifyContent: `flex-start`,
    marginBottom: `20px`,
    width: `100%`,
  }

  const Wrapper = styled.div`
    ${style}
  `

  return <Wrapper {...rest}>{children}</Wrapper>
}

export default Row
