import React from "react"
import { colors } from "../../../assets/themes/default"
import Column from "../Column"
import IconItem from "../../shared/IconItem"
import Row from "../../shared/Row"
import Text from "../../shared/Text"

const RequirementItem = ({ item, keyIndex }) => {
  return (
    <div key={keyIndex}>
      {keyIndex > 0 && (
        <Row>
          <Column>
            <hr />
          </Column>
        </Row>
      )}
      <Row>
        <Column>
          <IconItem iconName="right-arrow-blue">
            <Text color={colors.gray}>{item.requirement}</Text>
          </IconItem>
        </Column>
        <Column>
          <IconItem iconName="check-blue">
            <Text color={colors.gray}>{item.solution}</Text>
          </IconItem>
        </Column>
      </Row>
    </div>
  )
}

export default RequirementItem
