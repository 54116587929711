import React from "react"
import styled from "@emotion/styled"

const Wrapper = ({ children }) => {
  const styleWrapper = { marginBottom: `50px` }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  return <Wrapper>{children}</Wrapper>
}

export default Wrapper
