import React from "react"
import { colors } from "../../../assets/themes/default"
import Column from "../Column"
import Img from "gatsby-image"
import ResultItem from "../ResultItem"
import Row from "../../shared/Row"
import Text from "../../shared/Text"
import Wrapper from "../Wrapper"

const Results = ({ caseStudy }) => {
  const results = [
    {
      result: caseStudy.result1,
      description: caseStudy.resultDescription1,
    },
    {
      result: caseStudy.result2,
      description: caseStudy.resultDescription2,
    },
    {
      result: caseStudy.result3,
      description: caseStudy.resultDescription3,
    },
    {
      result: caseStudy.result4,
      description: caseStudy.resultDescription4,
    },
  ]
  return (
    <Wrapper>
      <Row>
        <Column>
          <Text color={colors.gray} type="title">
            Results
          </Text>
          <Text color={colors.gray} type="subsubtitle">
            {caseStudy.resultsOverview}
          </Text>
          {results.map((item, keyIndex) => {
            return (
              item.result && (
                <ResultItem item={item} key={keyIndex} keyIndex={keyIndex} />
              )
            )
          })}
        </Column>
        <Column>
          {caseStudy.resultsImage && (
            <Img fluid={caseStudy.resultsImage?.fluid} />
          )}
        </Column>
      </Row>
    </Wrapper>
  )
}

export default Results
