import React from "react"
import { colors } from "../../../assets/themes/default"
import Column from "../Column"
import RequirementItem from "../RequirementItem"
import Row from "../../shared/Row"
import Text from "../../shared/Text"
import Wrapper from "../Wrapper"

const Requirements = ({ caseStudy }) => {
  const requirements = [
    {
      requirement: caseStudy.requirement1,
      solution: caseStudy.requirementSolution1,
    },
    {
      requirement: caseStudy.requirement2,
      solution: caseStudy.requirementSolution2,
    },
    {
      requirement: caseStudy.requirement3,
      solution: caseStudy.requirementSolution3,
    },
  ]
  return (
    <Wrapper>
      <Row>
        <Column>
          <Text color={colors.gray} type="title">
            Challenges
          </Text>
        </Column>
        <Column>
          <Text color={colors.gray} type="title" id="OurSolutionTitle">
            Our solution
          </Text>
        </Column>
      </Row>
      {requirements.map((item, key) => {
        return (
          item.requirement && (
            <RequirementItem item={item} key={key} keyIndex={key} />
          )
        )
      })}
    </Wrapper>
  )
}

export default Requirements
