import React from "react"
import { colors } from "../assets/themes/default"
import { graphql } from "gatsby"
import Cover from "../components/shared/Cover"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Overview from "../components/case-study/Overview"
import Requirements from "../components/case-study/Requirements"
import Results from "../components/case-study/Results"
import Section from "../components/shared/Section"
import SEO from "../components/shared/SEO"
import styled from "@emotion/styled"
import Text from "../components/shared/Text"

const CaseStudy = ({ data }) => {
  const caseStudy = data.contentfulCaseStudy

  const styleImage = {
    margin: `auto`,
    marginBottom: `2rem`,
    maxWidth: `350px`,
  }

  const Image = styled(Img)`
    ${styleImage}
  `

  return (
    <Layout>
      <SEO title={caseStudy.title} />
      <Cover bgColor={colors.blue} bgImage={`/images/case-studies/bg.png`}>
        <Image
          fluid={caseStudy.companyLogo?.fluid}
          alt={caseStudy.companyName}
        />
        <Text color={colors.white} type="title">
          {caseStudy.title}
        </Text>
      </Cover>
      <Section>
        <Overview caseStudy={caseStudy} />
        <Requirements caseStudy={caseStudy} />
        <Results caseStudy={caseStudy} />
      </Section>
    </Layout>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      title
      slug
      image {
        fluid(maxWidth: 900, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      overview
      companyName
      companyInfo
      author
      authorInformation
      companyLogo {
        fluid(maxWidth: 350, quality: 98) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      requirement1
      requirementSolution1
      requirement2
      requirementSolution2
      requirement3
      requirementSolution3
      resultsOverview
      resultsImage {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      result1
      resultDescription1
      result2
      resultDescription2
      result3
      resultDescription3
      result4
      resultDescription4
      tags
      createdAt
    }
  }
`
