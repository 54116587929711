import React from "react"
import styled from "@emotion/styled"

const Column = ({ children }) => {
  const styleColumn = {
    flexBasis: `50%`,
    flexGrow: `1`,
    justifyContent: `space-around`,
    paddingLeft: `15px`,
    paddingRight: `15px`,
    textAlign: `left`,
    "@media (max-width:800px)": {
      flexBasis: `100%`,
      "#OurSolutionTitle": {
        display: `none`,
      },
    },
  }

  const Column = styled.div`
    ${styleColumn}
  `

  return <Column>{children}</Column>
}

export default Column
