import React from "react"
import styled from "@emotion/styled"
import Icon from "../Icon"
import { iconSizes } from "../../../assets/themes/default"

const IconItem = ({ iconName, children }) => {
  const styleWrapper = {
    display: `flex`,
  }

  const styleIconContainer = {
    minWidth: `${iconSizes.normal}`,
    paddingRight: `10px`,
  }

  const styleChildren = {}

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const IconContainer = styled.div`
    ${styleIconContainer}
  `

  const Children = styled.div`
    ${styleChildren}
  `

  return (
    <Wrapper>
      <IconContainer>
        <Icon name={iconName} size={iconSizes.normal} />
      </IconContainer>
      <Children>{children}</Children>
    </Wrapper>
  )
}

export default IconItem
