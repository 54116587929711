import React from "react"
import { colors, formattedDateFromString } from "../../../assets/themes/default"
import Author from "../Author"
import Column from "../Column"
import ListItems from "../../shared/ListItems"
import Row from "../../shared/Row"
import Text from "../../shared/Text"
import Wrapper from "../Wrapper"

const Overview = ({ caseStudy }) => {
  const showAuthor = false
  const showClient = false
  const showTags = false
  const showPublishedDate = false

  return (
    <Wrapper>
      <Row>
        <Column>
          <Text color={colors.gray} type="title">
            Overview
          </Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text color={colors.blue} type="subtitle">
            {caseStudy.overview}
          </Text>
          {showAuthor && (
            <Author
              name={caseStudy.author}
              information={caseStudy.authorInformation}
            />
          )}
        </Column>
        <Column>
          <Text color={colors.gray}>{caseStudy.companyInfo}</Text>
          {showClient && (
            <ListItems label="Client" items={[caseStudy.companyName]} />
          )}
          {showTags && <ListItems label="Tags" items={caseStudy.tags} />}
          {showPublishedDate && (
            <ListItems
              label="Publish date"
              items={[formattedDateFromString(caseStudy.createdAt)]}
            />
          )}
        </Column>
      </Row>
    </Wrapper>
  )
}

export default Overview
