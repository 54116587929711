import React from "react"
import { colors } from "../../../assets/themes/default"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Cover = ({ bgColor, bgImage, children, text, ...rest }) => {
  let styleWrapper = {
    alignItems: `center`,
    backgroundColor: bgColor,
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: `center`,
    borderBottom: `solid 2px ${colors.grayDark}`,
    display: `flex`,
    flexDirection: `row`,
    flexWrap: `wrap`,
    justifyContent: `space-around`,
    minHeight: `50vh`,
    paddingBottom: `30px`,
    paddingTop: `50px`,
    "@media (max-width:800px)": {
      backgroundSize: `cover`,
      flexDirection: `column`,
    },
  }

  const styleHeader = {
    alignItems: `center`,
    flexBasis: `100%`,
    margin: `0 auto`,
    maxWidth: `900px`,
    padding: `0 20px 0 20px`,
    textAlign: `center`,
    zIndex: `100`,
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const Header = styled.div`
    ${styleHeader}
  `

  return (
    <Wrapper {...rest}>
      <Header>{children}</Header>
    </Wrapper>
  )
}

Cover.propTypes = {
  bgColor: PropTypes.string,
}

Cover.defaultProps = {
  bgColor: `${colors.white}`,
}

export default Cover
