import React, { useState } from "react"
import { colors } from "../../../assets/themes/default"
import Column from "../Column"
import Icon from "../../shared/Icon"
import Row from "../../shared/Row"
import styled from "@emotion/styled"
import Text from "../../shared/Text"

import "./style.css"

const ResultItem = ({ item, keyIndex }) => {
  // The first item is open at start
  const [showDescription, setShowDescription] = useState(keyIndex === 0)

  const handleCheck = e => {
    e.preventDefault()
    setShowDescription(!showDescription)
  }

  const styleRow = {
    paddingBottom: `0px`,
    paddingTop: `20px`,
    margin: `0px`,
  }

  const styleHandle = {
    cursor: `pointer`,
    userSelect: `none`,
  }

  const styleIcon = {}

  const CustomRow = styled(Row)`
    ${styleRow}
  `

  const Handle = styled.a`
    ${styleHandle}
  `

  const CustomIcon = styled(Icon)`
    ${styleIcon}
  `

  return (
    <CustomRow key={keyIndex}>
      <Column>
        <Handle onClick={handleCheck}>
          <div style={{ display: `flex` }}>
            <CustomIcon
              name="down-open-arrow"
              className={showDescription && "down"}
            />
            <Text
              type="subsubtitle"
              color={colors.blue}
              style={{ marginLeft: `20px` }}
            >
              {item.result}
            </Text>
          </div>
        </Handle>
        {showDescription && (
          <Text color={colors.gray} style={{ marginLeft: `52px` }}>
            {item.description}
          </Text>
        )}
      </Column>
    </CustomRow>
  )
}

export default ResultItem
